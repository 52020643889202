import React from 'react';
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";

const ContactOne = () => {
    return (
        <>
            <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rn-contact-address mt_dec--30">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Contact Phone Number</h4>
                                        <p>Uneeb Ahmad:</p>                          
                                        <p><a href="tel:+92 321 6396643">+92 321 6396643</a></p>
                                        <p>Muhammad Abdullah:</p>                          
                                        <p><a href="tel:+92 320 7878090">+92 320 7878090</a></p>
                                        <p>Danish Goheer:</p>
                                        <p><a href="tel:+92 346 7893622">+92 346 7893622</a></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Our Email Address</h4>
                                        <p><a href="mailto:info@utecho.com">info@utecho.com</a></p>
                                        <p><a href="mailto:utechofsd@gmail.com">utechofsd@gmail.com</a></p>
                                        <p><a href="mailto:utechofsd@gmail.com">uneeb@utecho.com</a></p>
                                        <p><a href="mailto:abdullah@utecho.com">abdullah@utecho.com</a></p>
                                        <p><a href="mailto:danish@utecho.com">danish@utecho.com</a></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMapPin />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Our Location</h4>
                                        <p>135C Opposite Aqsa<br /> Hospital Batala Colony<br /> Satyana Road City, <br /> Faislabad.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt--40 row--15">
                <div className="col-lg-7">
                    <ContactForm formStyle="contact-form-1" />
                </div>
                <div className="col-lg-5 mt_md--30 mt_sm--30">
                    <GoogleMapStyle />
                </div>
            </div>
        </>
    )
}
export default ContactOne;