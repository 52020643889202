import React from 'react';
import Slider from "react-slick";
import { slickDot } from "../../utils/script";

const TestimonialData = [
    {
        form: "Pakistan",
        description: "Full stack developer can work on both the back-end and front-end of systems.They can develop fully fledged platforms",
        name: "Arman Talib",
        subtitle: "FULL STACK DEVELOPER",
        image: "arman-talib-1"
    },{
        form: "Pakistan",
        description: "Amazonians are customer obsessed. We love finding ways to make better.",
        name: "Hamza Ahmad",
        subtitle: "AMAZON EXPERT",
        image: "hamza ahmad-1"
    },
    {
        form: "Pakistan",
        description: "What I am primarily looking for with new projects is a fit on both a visual and aesthetic level as well as on a personal level with the client.",
        name: "Ahmad Bilal",
        subtitle: "SENIOR GRAPHIC DESIGNER",
        image: "ahmad-Bilal-1"
    },
    {
        form: "Pakistan",
        description: "Design and implement visually aesthetic user interfaces.",
        name: "Mian Ahmad",
        subtitle: "ANGULAR DEVELOPER",
        image: "ahmad-1"
    },
    {
        form: "Pakistan",
        description: "A Developer is a professional who is responsible for the design and construction.",
        name: "Basit Khaliq",
        subtitle: "SR WEB DEVELOPER",
        image: "basit-1"
    },
    {
        form: "Pakistan",
        description: "Front-end developers who build modern-day UI components to improvise application performance.",
        name: "Asad Ali",
        subtitle: "REACT DEVELOPER",
        image: "asad-Ali-1"
    },
    {
        form: "Pakistan",
        description: "Responsible for managing every aspect of the employment process, including orientation and training.",
        name: "Ammarah Qayyum",
        subtitle: "DIRECTOR HR",
        image: "girl-avatar"
    },
    {
        form: "Pakistan",
        description: "Software Testers are responsible for the quality of software development and deployment.",
        name: "Jawaria Ghaffar",
        subtitle: "SOFTWARE TESTER",
        image: "girl-avatar"
    },
    {
        form: "Pakistan",
        description: "Developer is responsible for developing new user-facing features, determining the structure.",
        name: "Huraira Younas",
        subtitle: "FRONT END DEVELOPER",
        image: "huraira-1"
    },
    {
        form: "Pakistan",
        description: "Python is a high-level, interpreted, interactive and object-oriented scripting language.",
        name: "Ahmad Raza",
        subtitle: "PYTHON",
        image: "ahmad-Raza-1"
    },
    {
        form: "Pakistan",
        description: "Building reusable components and front-end libraries for future use Translating designs and wireframes.",
        name: "Abuzar Zafar",
        subtitle: "IONIC DEVELOPER",
        image: "abuzar-1"
    },
    {
        form: "Pakistan",
        description: "React Native leverages common JavaScript skills while allowing a developer to simultaneously target both iOS and Android.",
        name: "Horair Ahmad",
        subtitle: "REACT NATIVE",
        image: "horair-1"
    },
    {
        form: "Pakistan",
        description: "Marketing all is about making connections, and the right time and place for connecting today is 'on the internet'. ",
        name: "Amna Ayub",
        subtitle: "DIGITAL MARKETING",
        image: "girl-avatar"
    },
    {
        form: "Pakistan",
        description: "The web developer, also called a web designer or webmaster, is the person who builds, adjusts, and/or maintains it.",
        name: "Soha Ijaz",
        subtitle: "WEB DEVELOPER",
        image: "girl-avatar"
    },
]
const TestimonialThree = ({teamStyle}) => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <Slider className="slick-space-gutter--15 rn-slick-dot rn-slick-arrow mb--60" {...slickDot}>
                    {TestimonialData.map((data, index) => (
                        <div key={index} className={`testimonial-style-two ${teamStyle}`}>
                            <div className="row align-items-center row--20">
                                <div className="order-2 order-md-1 col-lg-6 col-md-8 offset-lg-1">
                                    <div className="content mt_sm--40">
                                        <span className="form">{data.form}</span>
                                        <p className="description">{data.description}</p>
                                        <div className="client-info">
                                            <h4 className="title">{data.name}</h4>
                                            <h6 className="subtitle">{data.subtitle}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-md-2 col-lg-4 col-md-4">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`./images/testimonial/${data.image}.jpg`} alt="Corporate React Template" />
                                    </div>
                                </div>
                            </div> 
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}
export default TestimonialThree;




















