import React from 'react';
import ProjectDetailsContent from "../elements/portfolio/ProjectDetailsContent";

import PortfolioData from "../data/portfolio/PortfolioData2.json";
import SEO from "../common/SEO";
import Layout from "../common/Layout";


const ProjectDetail = ({match: {params: {id}}}) => {
    const portfolioId = parseInt(id, 10)
    const data = PortfolioData.filter(portfolio => portfolio.id === portfolioId);
    return (
        <>
            <SEO title="Portfolio Details || Doob" />
            <Layout>
                <ProjectDetailsContent data={data[0]} />
            </Layout>
        </>
    )
}

export default ProjectDetail;
