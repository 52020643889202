import React from "react";
import Slider from "react-slick";
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import { BannerActivation } from "../utils/script";
import Separator from "../elements/separator/Separator";
import CircleProgress from "../elements/progressbar/CircleProgress";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import PortfolioTwo from "../elements/portfolio/PortfolioTwo";
import ServiceFour from "../elements/service/ServiceFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import FooterTwo from "../common/footer/FooterTwo";
// import BlogList from "../components/blog/itemProp/BlogList";
// import BlogClassicData from "../data/blog/BlogList.json";
// import Testimonial from "../elements/testimonial/Testimonial";
import TestimonialOne from "../elements/testimonial/TestimonialOne";
import ScrollAnimation from "react-animate-on-scroll";
// var BlogListData = BlogClassicData.slice(0, 3);

const BannerData = [
    {
        image: "/images/bg/slider-image-1.jpg",
        title: "Emerge technology <br /> with us.",
        description:
            "Discover how Utecho does more with your time, <br /> budget and hand width.",
    },
    {
        image: "/images/bg/slider-image-2.jpg",
         title: "Make every change <br /> matter.",
     description:
           "We build the brands of future,<br /> Create and develop effortless sites and apps.",
    },
];

const Startup = () => {
    return (
        <>
            {/* <SEO title="Startup Agency" /> */}
            <main className="page-wrapper">
                <HeaderOne
                    btnStyle="btn-small btn-icon round"
                    HeaderSTyle="header-transparent"
                />

                {/* Start Slider Area  */}
                <Slider
                    className="slider-area slider-style-4 variation-2 slider-dot rn-slick-dot rn-slick-arrow"
                    {...BannerActivation}
                >
                    {BannerData.map((data, index) => (
                        <div key={index} className="single-slide">
                        
                            <div
                                className="height-950 bg-overlay bg_image"
                                style={{backgroundImage: `url(${process.env.PUBLIC_URL} ${data.image})`, }}
                            >
                                <div className="container">
                                    <div className="row row--30 align-items-center">
                                        <div className="col-lg-12">
                                            <div className="inner text-center">
                                                <h1 style={{textAlign:"left"}}
                                                    className="title"
                                                    dangerouslySetInnerHTML={{ __html: data.title }}
                                                ></h1>
                                                <p style={{textAlign:"left"}}
                                                    className="description"
                                                    dangerouslySetInnerHTML={{ __html: data.description }}
                                                ></p>
                                                <div className="button-group mt--10" style={{float:"left" }}>
                                                    <a
                                                        className="btn-default btn-large round"
                                                        target="_blank"
                                                        href="/contact"
                                                    >
                                                        CONTACT US
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
                {/* End Slider Area  */}

                <Separator />
                <div className="rwt-elements-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--25">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Our Experience"
                                    title="8+ years work Experience"
                                    description=""
                                />
                            </div>
                        </div>
                        <div className="col-lg-10 offset-lg-1 mt--10">
                            <CircleProgress />
                        </div>
                    </div>
                </div>

                <Separator />
                <div className="rwt-timeline-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Working Process"
                                    title="Our Working Process."
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1 mt--50">
                                <TimelineTwo classVar="no-gradient" />
                            </div>
                        </div>
                    </div>
                </div>

                <Separator />
                <div className="rwt-elements-area rn-section-gap">
                    <div className="container-fluid plr--30">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Portfolio"
                                    title="Our Completed Project!"
                                    description=""
                                />
                            </div>
                        </div>
                        <PortfolioTwo Column="col-lg-4 col-md-6 mt--30 box-grid-layout no-overlay" />
                    </div>
                </div>

                <Separator />
                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="What we can do for you"
                                    title="Services provide for you."
                                    description=""
                                />
                            </div>
                        </div>
                        <ServiceFour
                            serviceStyle="service__style--1 icon-circle-style with-working-process"
                            textAlign="text-center"
                        />
                    </div>
                </div>
                {/* End Service Area  */}

                {/* <Separator /> */}
                {/* key value start */}
                

                <div className="container">
                    <div className="key_text">
                        
                        <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                    <div className="flex-bar-right">
                            <div className="black-bar">
                            </div>
                        </div>
                        <h2 >Our key values</h2>
                        <p>Integrity creates trust as an organization, collectively it is our most important asset.<br /> Individually it’s the constant choice to infuse every action with honesty.”

                        </p>
                  

                        </ScrollAnimation>
                    </div>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                    
                    <div className="row">
                        <div style={{ maxWidth: '30%' }} className="key_col1 col-md-12 col-lg-4 col-sm-12  ">
                            <div className="card key_card">
                            <a href="http://localhost:3005/service"></a>
                                <div className="sincerity_div">
                                    <img style={{width:"130px"}} src="images/key_img/sincerity.svg" alt="" />
                                </div>
                                <div className="card-body key_cardBody">

                                    <h5 className="card-subtitle mb-2 text-muted">Sincerity</h5>
                                    <p className="card-text">Quality deliverance of work and sincerity to clients and organization.</p>

                                </div>
                            </div>
                        </div>
                        <div style={{ marginLeft: '42px', maxWidth: '30%' }} className="key_col2 col-md-12 col-lg-4 col-sm-12 ">
                            <div className="card key_card">
                                <div className="sincerity_div">
                                    <img style={{width:"130px"}} src="/images/key_img/dedication.svg" alt="" />
                                </div>
                                <div className="card-body key_cardBody">

                                    <h5 className="card-subtitle mb-2 text-muted">Dedication</h5>
                                    <p className="card-text">Ethical Values towards both the team members and clients, no matter what.</p>

                                </div>
                            </div>
                            <img className="dedication_second" style={{ marginTop:'-50px',width: '120px', marginLeft: "-70px" }} src="/images/key_img/dedication_second.png" alt="" />
                        </div>

                        <div style={{ marginBottom:'42px',marginTop: "150px", marginLeft: '42px', maxWidth: '30%' }} className="key_col3 col-md-12 col-lg-4 col-sm-12 ">
                            <div style={{ height: '100%' }} className="card key_card">
                                <div style={{ paddingTop: "40px !important" }} className="sincerity_div">
                                    <img style={{width:"130px"}} src="images/key_img/result_key.svg" alt="" />
                                </div>
                                <div className="card-body key_cardBody">

                                    <h5 className="card-subtitle mb-2 text-muted">Results</h5>
                                    <p className="card-text">Timely deliverance of work according to the clients need.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </ScrollAnimation>
                </div>
                <Separator />
                {/* Testimonial in home page area */}
                <div className="rwt-testimonial-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb--10">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    // subtitle="Client Feedback"
                                    title="Testimonial."
                                />
                            </div>
                        </div>
                        <TestimonialOne
                            column="col-lg-3 col-md-6 col-12 mt--30"
                            teamStyle="card-style-default testimonial-style-one"
                        />
                    </div>
                </div>

                <FooterTwo />
            </main>
        </>
    );
};

export default Startup;
