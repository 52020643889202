import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import GalleryOne from "./GalleryOne";


const PopupData = [
    {
        id: "01",
        image: "./images/gallery/gallery-1.jpg",
        popupLink: [
            './images/gallery/gallery-1.jpg',
        ],
    },
    {
        id: "02",
        image: "./images/gallery/gallery-2.jpg",
        popupLink: [
            './images/gallery/gallery-2.jpg',
        ],
    },
    {
        id: "03",
        image: "./images/gallery/gallery-3.jpg",
        popupLink: [
            './images/gallery/gallery-3.jpg',
        ],
    },
    {
        id: "04",
        image: "./images/gallery/gallery-4.jpg",
        popupLink: [
            './images/gallery/gallery-4.jpg',
        ],
    },
    {
        id: "05",
        image: "./images/gallery/gallery-5.jpg",
        popupLink: [
            './images/gallery/gallery-5.jpg',
        ],
    },
    {
        id: "06",
        image: "./images/gallery/gallery-6.jpg",
        popupLink: [
            './images/gallery/gallery-6.jpg',
        ],
    },
]

// const PopupData2 = [
//     {
//         id: "01",
//         image: "./images/portfolio/portfolio-01.jpg",
//         popupLink: [
//             './images/portfolio/portfolio-01.jpg',
//         ],
//     },
//     {
//         id: "02",
//         image: "./images/portfolio/portfolio-01.jpg",
//         popupLink: [
//             "./images/portfolio/portfolio-02.jpg",
//         ],
//     },
//     {
//         id: "03",
//         image: "./images/portfolio/portfolio-03.jpg",
//         popupLink: [
//             './images/portfolio/portfolio-03.jpg',
//         ],
//     },
//     {
//         id: "04",
//         image: "./images/portfolio/portfolio-04.jpg",
//         popupLink: [
//             './images/portfolio/portfolio-04.jpg',
//         ],
//     },
//     {
//         id: "05",
//         image: "./images/portfolio/portfolio-04.jpg",
//         popupLink: [
//             './images/portfolio/portfolio-04.jpg',
//         ],
//     },
//     {
//         id: "06",
//         image: "./images/portfolio/portfolio-05.jpg",
//         popupLink: [
//             './images/portfolio/portfolio-05.jpg',
//         ],
//     },
//     {
//         id: "07",
//         image: "./images/portfolio/portfolio-06.jpg",
//         popupLink: [
//             './images/portfolio/portfolio-06.jpg',
//         ],
//     },
//     {
//         id: "08",
//         image: "./images/portfolio/portfolio-01.jpg",
//         popupLink: [
//             './images/portfolio/portfolio-01.jpg',
//         ],
//     },
// ]

const Elements = () => {
    return (
        <>
            <SEO title="Gallery || Utecho" />
            <Layout>
                <BreadcrumbOne 
                    title="Visite Our Gallery."
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Gallery"
                />
                <div className="main-content">
           

                    {/* Start Elements Area  */}
                    <div className="rwt-gallery-area rn-section-gapBottom">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--20">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Gallery With Lightbox"
                                        title = "Our Gallery"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <div className="row mt_dec--30 row--15">
                                {PopupData.map((item) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30" key={item.id}>
                                        <GalleryOne galleryItem={item} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* End Elements Area  */}

                   



                </div>
            </Layout>
        </>
    )
}
export default Elements;