import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const teamData = [
    {
        image: 'arman-talib-1',
        name: 'Arman Talib',
        designation: 'FULL STACK DEVELOPER ',
        location: 'Pakistan', 
        description: 'Having 4+ years of experience who is leading the team from front.',   
    },
    {
        image: 'hamza ahmad-1',
        name: 'Hamza Ahmad',
        designation: 'AMAZON EXPERT',
        location: 'Pakistan', 
        description: 'Amazonians are customer obsessed. We love finding ways to make better.',   
    },
    {
        image: 'ahmad-Bilal-1',
        name: 'Ahmad Bilal',
        designation: 'SR. GRAPHIC DESIGNER',
        location: 'Pakistan', 
        description: 'Graphic design for all marketing materials: advertising, brochures.',
        
    },
   
    {
        image: 'ahmad-1',
        name: 'Mian Ahmad',
        designation: 'ANGULAR DEVELOPER',
        location: 'Pakistan', 
        description: 'Design and implement visually aesthetic user interfaces.',
        
    }, {
        image: 'basit-1',
        name: 'Basit Khaliq',
        designation: 'SR. WEB DEVELOPER',
        location: 'Pakistan', 
        description: 'A Developer is a professional who is responsible for the design and construction.',
        
    },
    {
        image: 'asad-Ali-1',
        name: 'Asad Ali',
        designation: 'REACT DEVELOPER',
        location: 'Pakistan', 
        description: 'Front-end developers who build modern-day UI components to improvise application performance.',
        
    },
    {
        image: 'girl-avatar',
        name: 'Ammarah Qayyum',
        designation: 'DIRECTOR HR',
        location: 'Pakistan', 
        description: 'Responsible for managing every aspect of the employment process, including orientation and training.',
        
    },  {
        image: 'girl-avatar',
        name: 'Jawaria Ghaffar',
        designation: 'SOFTWARE TESTER',
        location: 'Pakistan', 
        description: 'Software Testers are responsible for the quality of software development and deployment.',
        
    },
    {
        image: 'huraira-1',
        name: 'Huraira Younas',
        designation: 'FRONT END DEVELOPER',
        location: 'Pakistan', 
        description: ' Developer is responsible for developing new user-facing features, determining the structure.',
        
    },
    {
        image: 'ahmad-Raza-1',
        name: 'Ahmad Raza',
        designation: 'PYTHON',
        location: 'Pakistan', 
        description: 'Python is a high-level, interpreted, interactive and object-oriented scripting language.',
        
    },
    {
        image: 'abuzar-1',
        name: 'Abuzar Zafar',
        designation: 'IONIC DEVELOPER',
        location: 'Pakistan', 
        description: 'Building reusable components and front-end libraries for future use Translating designs and wireframes.',
        
    },
    {
        image: 'horair-1',
        name: 'Horair Ahmad',
        designation: 'REACT NATIVE',
        location: 'Pakistan', 
        description: 'React Native leverages common JavaScript skills while allowing a developer to simultaneously target both iOS and Android.',
        
    },
    {
        image: 'girl-avatar',
        name: 'Amna Ayub',
        designation: 'DIGITAL MARKETING',
        location: 'Pakistan', 
        description: 'Marketing all is about making connections, and the right time and place for connecting today is "on the internet".',
        
    },
    {
        image: 'girl-avatar',
        name: 'Soha Ijaz',
        designation: 'WEB DEVELOPER',
        location: 'Pakistan', 
        description: 'The web developer, also called a web designer or webmaster, is the person who builds, adjusts, and/or maintains it.".',
        
    },
    
]


const TeamOne = ({column , teamStyle}) => {
    return (
        <div className="row row--15">
            {teamData.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <div className={`rn-team ${teamStyle}`}>
                        <ScrollAnimation 
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                            <div className="inner">
                                <figure className="thumbnail">
                                    <img src={`./images/team/${data.image}.jpg`} alt="Corporate React Template" />
                                </figure>
                                <figcaption className="content">
                                    <h2 className="title">{data.name}</h2>
                                    <h6 className="subtitle theme-gradient">{data.designation}</h6>
                                    <span className="team-form">
                                        <img src="./images/team/location.svg" alt="Corporate React Template" />
                                        <span className="location">{data.location}</span>
                                    </span>
                                    <p className="description">{data.description}</p>

                                    {/* <ul className="social-icon social-default icon-naked mt--20" >
                                        {data.socialNetwork.map((social, index) =>
                                            <li key={index}><a href={`${social.url}`}>{social.icon}</a></li>
                                        )}
                                    </ul> */}
                                </figcaption>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default TeamOne;
